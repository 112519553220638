// rootReducer.js
import { combineReducers } from "redux";
import authReducer from "./auth/login/reducer";
import signupReducer from "./auth/register/reducer";
import guestsReducer from "./guests/reducer";
import teamsReducer from "./teams/reducer";
import templatesReducer from "./templates/reducer";
import eventsReducer from "./events/reducer";
import helpRequestsReducer from "./auth/helpRequest/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  signupReducer,
  guestsReducer,
  teamsReducer,
  templatesReducer,
  eventsReducer,
  helpRequestsReducer
});

export default rootReducer;
