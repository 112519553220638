
import React, { useState, useEffect } from 'react';
import {
  MessageSquare,
  Users,
  UserPlus,
  Menu,
  UserCircle,
  HelpCircle as HelpCircleIcon,
} from 'lucide-react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // <-- For Redux

import { fetchHelpRequests } from '../store/auth/helpRequest/actions';

import Guests from './GuestManager';
import NewChecklist from './NewChecklist';
import SetUpTeam from '../comunications/SetUpTeam';
import Teams from './Team';
import Bulletins from './Bulletins';
import Help from './Help';
import Profile from './Profile';
const NAME = localStorage.getItem('username');
 

export default function Component() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { eventName, eventId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { activeRequests } = useSelector((state) => state.helpRequestsReducer);

  useEffect(() => {
    if (!eventId) return;

    dispatch(fetchHelpRequests(eventId, 'active'));
    dispatch(fetchHelpRequests(eventId, 'done'));
  
  }, [dispatch, eventId]);

  const activeHelpCount = activeRequests?.length || 0;

  const getActiveSection = () => {
    const path = location.pathname.split('/');
    return path[path.length - 1] || 'bulletins';
  };
  const [activeSection, setActiveSection] = useState(getActiveSection());

  useEffect(() => {
    setActiveSection(getActiveSection());
  }, [location]);
  const {users } = useSelector((state) => ({
    users: state.teamsReducer.teamMembers,
  }));
  console.log("usersusers",users);
  const communicationTeamMembers = users.filter(user=>user.team ==='communications team');
  console.log("communicationTeamMembers", communicationTeamMembers);

  const handleSectionChange = (section) => {
    
    if (section === 'communications') {
  const hasPermission = communicationTeamMembers.some(
      (member) => member.name.toLowerCase() === NAME.toLowerCase()
    );
    console.log("haspermission",hasPermission)      
      if (hasPermission) {
        setActiveSection(section);

        navigate(`/${eventId}/${encodeURIComponent(eventName)}/${section}`);
      } else {
        alert('You do not have permission to access the Communications section.');
        return;
      
        
      }
    } else {
      setActiveSection(section);
      navigate(`/${eventId}/${encodeURIComponent(eventName)}/${section}`);
    }
  };

  const renderSection = () => {
    switch (activeSection) {
      case 'bulletins':
        return <Bulletins />;
      case 'guests':
        return <Guests />;
      case 'newchecklist':
        return <NewChecklist />;
      case 'teams':
        return <Teams />;
      case 'communications':
        return <SetUpTeam />;
      case 'help':
        return <Help />;
        case 'profile':
          return <Profile/>;
      default:
        return <Bulletins />;
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <button
        className="md:hidden p-2 fixed top-4 left-4 z-20 text-gray-600 bg-transparent hover:text-black focus:outline-none"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <Menu className="w-6 h-6" />
        {activeHelpCount > 0 && (
          <span
            className="
              absolute
              top-1
              right-1
              bg-red-600
              text-white
              text-xs
              font-bold
              rounded-full
              px-1
              py-0
              transform
              translate-x-1/2
              -translate-y-1/2
            "
            style={{ minWidth: '1rem', height: '1rem' }}
          >
            {activeHelpCount}
          </span>
        )}      </button>

      <div
        className={`fixed inset-y-0 left-0 w-64 bg-gray-800 text-white p-4 transform ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } md:relative md:translate-x-0 transition-transform duration-300 ease-in-out z-30`}
      >
        <h1
          className="text-2xl font-bold mb-8 cursor-pointer"
          onClick={() => navigate('/homepage')}
        >
          Event: {eventName}
        </h1>

        <nav className="space-y-2">
          {/* Guests */}
          <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'guests' ? 'bg-blue-600' : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('guests')}
          >
            <Users className="inline-block mr-2" size={18} /> Guests
          </button>

          <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'teams' ? 'bg-blue-600' : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('teams')}
          >
            <UserPlus className="inline-block mr-2" size={18} /> Teams
          </button>
          
          <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'communications' ? 'bg-blue-600' : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('communications')}
          >
            <MessageSquare className="inline-block mr-2" size={18} /> Communications
          </button>

          {/* Help */}
          <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'help' ? 'bg-blue-600' : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('help')}
          >
            <HelpCircleIcon className="inline-block mr-2" size={18} />
            Help ({activeHelpCount})
          </button>
          <button
            className={`w-full text-left py-2 px-4 rounded ${
              activeSection === 'profile' ? 'bg-blue-600' : 'hover:bg-gray-700'
            }`}
            onClick={() => handleSectionChange('profile')}
          >
            <UserCircle className="inline-block mr-2" size={18} />
            Profile
          </button>
        </nav>
      </div>

      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-5000 z-10000"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}

      <div className="flex-1 p-8 overflow-auto">
        <h2 className="text-3xl font-bold m-3">{eventName}</h2>
        {renderSection()}
      </div>
    </div>
  );
}
