import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./store/reducers";
import rootSaga from "./store/sagas";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false, // Disable thunk since we're using redux-saga
      serializableCheck: false, // Optional: To prevent serializable check errors with non-plain objects
    }).concat(sagaMiddleware), // Add saga middleware
});

sagaMiddleware.run(rootSaga);

export default store;
