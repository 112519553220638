
import './App.css';
import Landingpage from './pages/Landingpage';
// import TemplateSelection from './comunications/TemplateSelection';
// import MessageStatus from './comunications/MessageStatus';
// import EventUpdate from './comunications/EventUpdate';
// import BroadcastMessage from './comunications/BroadcastMessage';
// import AudienceSelection from './comunications/AudienceSelection';


function App() {
  return (
   <>
    <Landingpage/>
   </>
  );
}

export default App;
