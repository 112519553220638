import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AuthForm from './pages/AuthForm';
import HomePage from './pages/HomePage';
import WorkSpace from './components/WorkSpace';
import AuthMiddleware from './components/AuthMiddleware'; // Import AuthMiddleware
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/log_in",
    element: <AuthForm />,
  },
  {
    path: "/sign_up",
    element: <AuthForm />,
  },
  {
    element: <AuthMiddleware />, // Protects the following routes
    children: [
      {
        path: "/homepage",
        element: <HomePage />,
      },
      {
        path: "/workspace/:eventName/*",
        element: <WorkSpace />,
        children: [
          { path: "bulletins", element: <WorkSpace /> },
          { path: "guests", element: <WorkSpace /> },
          { path: "newchecklist", element: <WorkSpace /> },
          { path: "communications", element: <WorkSpace /> },
        ],
      },
      {
        path: "/check_list",
        element: <HomePage />,
      },
      {
        path: "/guest_manager",
        element: <HomePage />,
      },
    ],
  },
  {
    path: "*",
    element: <div>404 Page Not Found</div>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
