import React, { useEffect, useState } from 'react';
import { Button } from "../components/ui/Button";
import { Users, Plus } from "lucide-react";
import Template from './Template';
import EventUpdate from './EventUpdate';
import { useDispatch, useSelector } from 'react-redux';
import ReusableTable from '../components/ReusableTable';
import { getTemplateList } from '../store/templates/actions';
import { useParams } from 'react-router-dom';

export default function DraftSection() {
  const { templates, templatesLoading } = useSelector((state) => ({
    templates: state.templatesReducer.templateList,
    templatesLoading: state.templatesReducer.loading,
  }))
  const [showTemplate, setShowTemplate] = useState(false); 
  const [selectedTemplate, setSelectedTemplate] = useState(true);
  const [showEventUpdate, setShowEventUpdate] = useState(false); 
  const [error, setError] = useState(false); 
  const dispatch = useDispatch();
  const { eventId } = useParams();
  
  useEffect(()=>{
    dispatch(getTemplateList(eventId))
  },[dispatch])

  const handleSelect = (template) => {
    setSelectedTemplate(template);
    setShowTemplate(false); 
    setShowEventUpdate(true); 
  };

  if (showTemplate) {
    return <Template onSwitch={() => setShowTemplate(false)} />;
  }

  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Type', field: 'type' },
  ];


  return (
    <div className="container mx-auto p-4">
      {showEventUpdate ? (
        // Show only EventUpdate component when showEventUpdate is true
        <div className="p-4">
          <EventUpdate templateData={selectedTemplate} setShowEventUpdate={setShowEventUpdate}/>
        </div>
      ) : (
        // Show DraftSection content if showEventUpdate is false
        <div>
          {/* <div className="flex justify-end gap-2 mb-4">
            <Button variant="outline" size="sm" className="flex items-center gap-2">
              <Users className="h-4 w-4" />
              Team
            </Button>
          </div> */}
          <h1 className="text-2xl font-bold mb-4">Templates List</h1>

          {templatesLoading ? (
            <p>Loading templates...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : templates.length > 0 ? (
            <ReusableTable
              columns={columns}
              data={templates}
              onActionClick={(template) => handleSelect(template)}
              actionText="Select"
            />
          ) : (
            <p>No templates found.</p>
          )}
          <div className="mt-4 flex justify-start">
            <Button variant="outline" size="icon">
              <Plus className="h-4 w-4" />
              <span className="sr-only">Add</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
