// rootSaga.js
import { all, fork } from "redux-saga/effects";
import { loginSaga } from "./auth/login/saga";
import { signupSaga } from "./auth/register/saga";
import { guestSaga } from "./guests/saga";
import { teamsSaga } from "./teams/saga";
import { templatesSaga } from "./templates/saga";
import { eventsSaga } from "./events/saga";
import watchHelpRequests from "./auth/helpRequest/Saga";


export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(signupSaga),
    fork(guestSaga),
    fork(teamsSaga),
    fork(templatesSaga),
    fork(eventsSaga),
    fork(watchHelpRequests),
  ]);
}
