// src/utils/checkResponsesForTokenError.js

const handleInvalidToken = () => {
    localStorage.clear();
    alert("Your session has expired. Please log in again");
  }
  
  const checkResponsesForTokenError = async (response) => {
    if (!response.ok) {
      const errorReceived = await response.json();
      if (errorReceived.errorType === 'invalidToken') {
        handleInvalidToken();
        throw new Error('Token expired. User logged out.');
      }
      throw new Error(errorReceived.error || 'An unknown error occurred.');
    }
  
    // Parse the response body once
    const data = await response.json();
    console.log("Parsed Data:", data); // Log the parsed data
    return data;
  }
  
  export default checkResponsesForTokenError;
  