import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthMiddleware = () => {
  const { userAuthToken, authLoading } = useSelector((state) => ({
    userAuthToken: state.auth.userAuthToken || localStorage.getItem('authToken'),
    authLoading: state.auth.loading,
  }));

  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    if (!authLoading) {
      setAuthChecked(true);
    }
  }, [authLoading]);

  if (!authChecked) return null;

  return userAuthToken ? <Outlet /> : <Navigate to="/log_in" />;
};

export default AuthMiddleware;
