// src/pages/HelpPage.js

import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';

import { fetchHelpRequests } from '../store/auth/helpRequest/actions';
import { updateHelpRequestStatus } from '../utils/Api';

export default function HelpPage() {
  const { eventId } = useParams();
  const dispatch = useDispatch();

  const {
    loading,
    activeRequests,
    historyRequests,
    error
    
  } = useSelector((state) => state.helpRequestsReducer);
  console.log("ACTIVE",activeRequests,"history",historyRequests);

  const [errorActive, setErrorActive] = useState(null);
  const [errorHistory, setErrorHistory] = useState(null);

  const [loadingActive, setLoadingActive] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);

  const prevActiveHelpRequestsRef = useRef([]);

  const columns = ['id', 'Help', 'Phone Number'];

  useEffect(() => {
    if (Array.isArray(activeRequests) && activeRequests.length > 0) {
      const previousIds = new Set(prevActiveHelpRequestsRef.current.map(req => req.id));
      const newRequests = activeRequests.filter(req => !previousIds.has(req.id));

      if (newRequests.length > 0) {
        toast.info(`${newRequests.length} new help request(s) added.`);
      }

      prevActiveHelpRequestsRef.current = activeRequests;
    }
  }, [activeRequests]);

  useEffect(() => {
    if (!eventId) return;

    setLoadingActive(true);
    setLoadingHistory(true);
    setErrorActive(null);
    setErrorHistory(null);

    dispatch(fetchHelpRequests(eventId, 'done'));
    dispatch(fetchHelpRequests(eventId, 'active'));
   
  }, [dispatch, eventId]);

  useEffect(() => {
    setLoadingActive(loading);
    setLoadingHistory(loading);

    if (error) {
      setErrorActive(error);
      setErrorHistory(error);
    }
  }, [loading, error]);

  const handleMarkDone = async (request) => {
    const confirmAction = window.confirm('Are you sure you want to mark this request as done?');
    if (!confirmAction) return;

    try {
      await updateHelpRequestStatus(eventId, request.id, 'done');
      prevActiveHelpRequestsRef.current = prevActiveHelpRequestsRef.current.filter(
        (r) => r.id !== request.id
      );
    } catch (error) {
      alert(`Failed to mark request as done: ${error.message}`);
    }
  };

  const formatCellValue = (column, request) => {
    if (column === 'Help') {
      return request.message;
    } else if (column === 'Phone Number') {
      return request.phoneNumber;
    } else if (column === 'id') {
      return request.id;
    }
    return '';
  };

  return (
    <div className="w-full max-w-full p-4">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />

      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Help Information</h2>
      </div>

      <section className="mb-8">
        <h3 className="text-md font-semibold mb-2">Active Help Requests</h3>

        {loadingActive && (
          <div className="text-center text-gray-500">Loading active help requests...</div>
        )}
        {errorActive && (
          <div className="text-center text-red-500">Error: {errorActive}</div>
        )}
        {!loadingActive && !errorActive && activeRequests.length === 0 && (
          <div className="text-center text-gray-500">No active help requests found.</div>
        )}

        {activeRequests.length > 0 && (
          <div className="overflow-x-auto shadow-md rounded-lg">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Mark Done
                  </th>
                  {columns.map((column, index) => (
                    <th
                      key={index}
                      className="px-4 py-2 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                    >
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {activeRequests.map((request) => (
                  <tr key={request.id} className="border-b">
                    <td className="px-4 py-2 text-sm text-blue-500 align-top">
                      <button
                        onClick={() => handleMarkDone(request)}
                        className="hover:underline"
                      >
                        Mark Done
                      </button>
                    </td>
                    {columns.map((column, colIndex) => (
                      <td
                        key={colIndex}
                        className="px-4 py-2 text-sm text-gray-800 align-top"
                      >
                        {formatCellValue(column, request)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </section>

      {/* History Section */}
      <section>
        <h3 className="text-md font-semibold mb-2">History</h3>

        {loadingHistory && (
          <div className="text-center text-gray-500">Loading help requests history...</div>
        )}
        {errorHistory && (
          <div className="text-center text-red-500">Error: {errorHistory}</div>
        )}
        {!loadingHistory && !errorHistory && historyRequests.length === 0 && (
          <div className="text-center text-gray-500">No help requests in history.</div>
        )}

        {historyRequests.length > 0 && (
          <div className="overflow-x-auto shadow-md rounded-lg">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-100">
                <tr>
                  {['Help', 'Phone Number', 'Status','Approver','Created At'].map((column, index) => (
                    <th
                      key={index}
                      className="px-4 py-2 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                    >
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {historyRequests.map((request) => (
                  <tr key={request.id} className="border-b">
                    <td className="px-4 py-2 text-sm text-gray-800 align-top break-words">
                      {request.message}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-800 align-top break-words">
                      {request.phoneNumber}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-800 align-top break-words">
                      {request.status}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-800 align-top break-words">
                      {request.Approver}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-800 align-top break-words">
                      {new Date(request.createdAt).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </section>
    </div>
  );
}
