// rootReducer.js
import { combineReducers } from "redux";
import authReducer from "./auth/login/reducer";
import signupReducer from "./auth/register/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  signupReducer
});

export default rootReducer;
