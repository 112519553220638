// src/store/helpRequests/reducer.js

import {
  FETCH_HELP_REQUESTS,
  FETCH_HELP_REQUESTS_SUCCESS,
  FETCH_HELP_REQUESTS_FAILURE,
} from './actionTypes';

const initialState = {
  loading: false,
  activeRequests: [],
  historyRequests: [],
  error: null,
};

const helpRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HELP_REQUESTS:
      return {
        ...state,
        loading: true,
        error: null,
      };

      case FETCH_HELP_REQUESTS_SUCCESS: {
        const { status, data } = action.payload;
        const newState = {
          ...state,
          loading: false,
          error: null,
          activeRequests: status === 'active' ? data : state.activeRequests,
          historyRequests: status === 'done' ? data : state.historyRequests,
        };
        console.log('Reducer new state:', newState);
        return newState;
      }
      

    case FETCH_HELP_REQUESTS_FAILURE: {
      const { status, error } = action.payload;
      return {
        ...state,
        loading: false,
        // Depending on how you want to handle errors for each type of request, 
        // you could store separate errors for active/done if needed.
        error,
      };
    }

    default:
      return state;
  }
};

export default helpRequestsReducer;
