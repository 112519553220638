import config from "../config";
import checkResponsesForTokenError from "./authTokenExpiryCheck";
// const BACK_END_URL = 'http://localhost:3002'

const login = async (email, password) => {
  const isEmail = inputIsEmail(email)
  try {
    const response = await fetch(`${config.BACKEND_URL}/users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        email: isEmail ? email : '',
        phone_number: !isEmail ? email : '',
        password: password
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const inputIsEmail = (input) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailRegex.test(input)) {
    return true;
  }
  return false
};


const signup = async (userName, email, password, phoneNumber) => {
  const isEmail = inputIsEmail(email)
  try {
    const response = await fetch(`${config.BACKEND_URL}/users/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        name: userName,
        phone_number: !isEmail ? email : '',
        email: email,
        password: password
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const uploadGuests = async (eventId, guestList) => {
  guestList.forEach(guest=>{
  })
  try {
    console.log("6969",guestList);

    const response = await fetch(`${config.BACKEND_URL}/events/upload-guest-list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
      body: JSON.stringify({
        eventId: eventId,
        guestList: guestList
      }),
    });


    return await checkResponsesForTokenError(response);
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getGuestsList = async (eventId, filters) => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/events/guest-list/${eventId}?category=${filters}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
    });
    

    return await checkResponsesForTokenError(response);
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getTemplates = async (eventId) => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/templates?eventId=${eventId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
    });

    return await checkResponsesForTokenError(response);
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const uploadteams = async (eventId, teamList) => {
  console.log("eventID",eventId,"teamList",teamList);
  try {
    const response = await fetch(`${config.BACKEND_URL}/events/create-team`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
      body: JSON.stringify({
        eventId: eventId,
        teamData: teamList
      }),
    });

  
    return await checkResponsesForTokenError(response);
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
// In your actions file
export const updateGuest = async (eventId, guestId, updates) => {
  console.log("878787",eventId, guestId, updates);
  try {
    const response = await fetch(`${config.BACKEND_URL}/events/${eventId}/guests/${guestId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
      body: JSON.stringify(updates),
    });

    return await checkResponsesForTokenError(response);
  } catch (error) {
    console.error('Error updating guest:', error);
    throw error;
  }
};
export const getHelpRequests = async (eventId,status) => { // Added 'async'
  console.log("eventID",eventId,"status",status)
  try {
    const username = localStorage.getItem('username');
    const response = await fetch(`${config.BACKEND_URL}/events/${eventId}?status=${status}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
    
    });
    
    return await checkResponsesForTokenError(response);
  } catch (error) { // Corrected catch syntax
    console.error('Error fetching data:', error);
    throw error;
  }
};
export async function updateDriveLink({ eventId, driveUrl, pages }) {
  try {
    const response = await fetch(
      `${config.BACKEND_URL}/events/drivelinks/${eventId}?drive_url=${encodeURIComponent(driveUrl)}&pages=${pages}`,
      {
        method: 'POST',
      }
    );

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.error || 'Failed to update drive link');
    }

    return await response.json();
  } catch (err) {
    throw err;
  }
}
export const updateHelpRequestStatus = async (eventId, requestId, status = 'done') => {
  try {
    const username = localStorage.getItem('username');
    const response = await fetch(`${config.BACKEND_URL}/events/${eventId}/${requestId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      body: JSON.stringify({ status,username }), // If your backend expects a body
    });

    return await checkResponsesForTokenError(response);
  } catch (error) {
    console.error('Error updating help request status:', error);
    throw error;
  }
};


const getTeam = async (eventId) => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/events/team-data/${eventId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
    });

   
    return await checkResponsesForTokenError(response);
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}


const createTeam = async (eventId, teamData) => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/events/create-team`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
      body: JSON.stringify({
        eventId: eventId,
        teamData: teamData
      })
    });

    return await checkResponsesForTokenError(response);
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getAllEvents = async () => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/events`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
    });

   
    return await checkResponsesForTokenError(response);
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getOneEvent = async (eventId) => {
  try {
    const response = await fetch(`${config.BACKEND_URL}/events/${eventId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
      credentials: 'include',
    });


    return await checkResponsesForTokenError(response);
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}


const createEventApi = async (event) => {
 console.log("event",event)
  try {
    const response = await fetch(`${config.BACKEND_URL}/events`, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('authToken'),
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        name: event.EventList.name,
        description: event.EventList.description,
        end_date: event.EventList.end_date,
        start_date: event.EventList.start_date,
      }),
    });

   
    return await checkResponsesForTokenError(response);
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export { login, signup, uploadGuests, uploadteams, getTeam, createTeam, getGuestsList, getTemplates, getAllEvents, createEventApi, getOneEvent }
