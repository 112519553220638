// rootSaga.js
import { all, fork } from "redux-saga/effects";
import { loginSaga } from "./auth/login/saga";
import { signupSaga } from "./auth/register/saga";


export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(signupSaga)
  ]);
}
