// src/store/helpRequests/saga.js
import { call, put, takeEvery} from 'redux-saga/effects';
import { getHelpRequests } from '../../../utils/Api';
import {
  FETCH_HELP_REQUESTS,
} from './actionTypes';
import {
  fetchHelpRequestsSuccess,
  fetchHelpRequestsFailure,
} from './actions';

function* fetchHelpRequestsSaga(action) {
  try {
    const { eventId, status } = action.payload;
    // Call the API with the eventId and status
    const data = yield call(getHelpRequests, eventId, status);
    console.log(17,data);
    yield put(fetchHelpRequestsSuccess(status, data));
  } catch (error) {
    // Dispatch failure
    yield put(fetchHelpRequestsFailure(
      action.payload.status,
      error.message || 'Error fetching help requests'
    ));
  }
}

export default function* watchHelpRequests() {
  yield takeEvery(FETCH_HELP_REQUESTS, fetchHelpRequestsSaga);
}
