// src/store/helpRequests/actions.js

import {
    FETCH_HELP_REQUESTS,
    FETCH_HELP_REQUESTS_SUCCESS,
    FETCH_HELP_REQUESTS_FAILURE,
  } from './actionTypes';
  
  export const fetchHelpRequests = (eventId, status) => ({
    type: FETCH_HELP_REQUESTS,
    payload: { eventId, status },
  });
  
  export const fetchHelpRequestsSuccess = (status, data) => ({
    type: FETCH_HELP_REQUESTS_SUCCESS,
    payload: { status, data },
  });
  
  export const fetchHelpRequestsFailure = (status, error) => ({
    type: FETCH_HELP_REQUESTS_FAILURE,
    payload: { status, error },
  });
  