import React from 'react';
import Button from '../components/Button';
import FeatureCard from '../components/FeatureCard';
import Header from '../components/Header';
import CanvaEmbed1 from './CanvaEmded1';
import CanvaEmbed2 from './CanvaEmbed2';
import CanvaEmbed3 from './CanvaEmbed3';
import CanvaEmbed4 from './CanvaEmbed4';

const LandingPage = () => {
  const handleGetStarted = () => {
    window.location.href = ("https://wa.aisensy.com/6qAljY");
  };
  
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8 text-center">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">
          Event Management Simplified.
        </h1>
        <p className="text-xl text-gray-600 mb-8">
          EVENTSA makes it easy to manage real-time event operations.
        </p>
        <Button onClick = {handleGetStarted}>Get Started</Button>

        
        <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <FeatureCard title="Send personalised bulk Whatsapp messages in just 2 clicks " />
            <CanvaEmbed1 />
          </div>
          <div>
            <FeatureCard title="Track and manage RSVPs automatically." />
            <CanvaEmbed2 />
          </div>
        </div>

        <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <FeatureCard title="Resolve guest requests instantly." />
            <CanvaEmbed3 />
          </div>
          <div>
            <FeatureCard title="Collect & share photos effortlessly." />
            <CanvaEmbed4 />
          </div>
        </div>
      </main>
    </div>
  );
};

export default LandingPage;
