import { takeLatest, call, put } from 'redux-saga/effects';
import { loginSuccess, loginFailure } from './actions';
import { LOGIN_REQUEST } from './actionTypes';
import { STORAGE_KEY } from '../../../helpers/storage_helper';
import { login } from '../../../utils/Api';


export function* handleLogin(action) {
  const { email, password } = action.payload;

  try {
    const userData = yield call(login, email, password);
    localStorage.setItem(STORAGE_KEY.AUTH_TOKEN, (userData.token));
    localStorage.setItem(STORAGE_KEY.USERNAME,userData.username);
    yield put(loginSuccess(userData));
  } catch (error) {
    yield put(loginFailure(error.message));
  }
}

export function* loginSaga() {
  yield takeLatest(LOGIN_REQUEST, handleLogin);
}
